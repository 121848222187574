@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat Italic';
    src: url('./assets/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
  }
  